<template>
  <Bar :chart-options="chartOptions" :chart-data="chartData" :style="{ height: `${height}px` }" />
</template>

<script>
import { Bar } from 'vue-chartjs'

import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip } from 'chart.js'

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip)

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export default {
  props: {
    probabilities: {
      type: Array,
      required: true
    },

    height: {
      type: Number,
      default: 40
    }
  },

  computed: {
    weekly () {
      return this.probabilities.length == 48
    },

    chartOptions () {
      return {
        animation: false,

        responsive: true,
        maintainAspectRatio: false,

        scales: {
          x: {
            ticks: { display: false },
            grid: { display: false, drawBorder: false }
          },

          y: {
            beginAtZero: true,
            max: 100,
            ticks: { display: false },
            grid: { display: false, drawBorder: false }
          }
        },

        plugins: {
          tooltip: {
            xAlign: 'left',

            titleMarginBottom: 2,
            padding: 3,
            caretSize: 0,

            bodyAlign: 'right',
            displayColors: false,
            intersect: false,

            callbacks: {
              title: function (context) {
                const x = context[0].parsed.x

                if (this.weekly) {
                  const month = MONTHS[Math.floor(x / 4)]
                  const week = x % 4 + 1

                  return `${month} Week ${week}`
                } else {
                  const month = MONTHS[x]
                  return month
                }
              },

              label: function (context) {
                if (context.parsed.y !== null) {
                  return `${context.parsed.y.toFixed(1)}%`
                }
              }
            }
          }
        }
      }
    },

    chartData () {
      return {
        labels: Array.from({ length: this.probabilities.length }, (_, i) => i + 1),
        datasets: [{
          data: this.probabilities.map(p => 100 * p),
          backgroundColor: '#429488',
          categoryPercentage: 1.0
        }]
      }
    }
  },

  components: {
    Bar
  }
}
</script>
