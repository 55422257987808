<template>
  <div class="d-flex align-items-center py-1 pe-3">
    <div class="flex-shrink-0">
      <SpeciesThumbnail :species="species" class="d-block small" />
    </div>

    <div class="flex-grow-1 mx-2 x-small lh-sm">
      <p class="mb-0">{{ species.commonName }}</p>
      <p class="mb-0 text-muted fst-italic">({{ species.scientificName }})</p>
    </div>

    <div class="w-50">
      <SpeciesProbabilityChart :probabilities="probabilities" />
    </div>
  </div>
</template>

<script>
import SpeciesProbabilityChart from './SpeciesProbabilityChart'
import SpeciesThumbnail from './SpeciesThumbnail'

export default {
  props: {
    species: {
      type: Object,
      required: true
    },

    weeks: {
      type: Array,
      required: false
    },

    months: {
      type: Array,
      required: false
    }
  },

  computed: {
    probabilities () {
      return this.weeks || this.months
    }
  },

  components: {
    SpeciesProbabilityChart,
    SpeciesThumbnail
  }
}
</script>
