<template>
  <div>
    <l-marker v-if="hasDetections" :lat-lng="coords" :icon="icon" :opacity="opacity" :pane="pane || station.type" @click="$emit('click', station)" @tooltipopen="loadInfo">
      <l-tooltip v-if="tooltip" :options="{ offset: tooltipOffset, className: tooltipClass }">
        <StationInfo :station="station" :period="period" :tooltip="true" ref="info" />
      </l-tooltip>
    </l-marker>
    <l-circle v-if="selected && station.locationPrivacy" :lat-lng="coords" :radius="1560" color="#429488" fill-color="#429488" />
  </div>
</template>

<script>
import StationInfo from '../StationInfo'
import { LCircle, LMarker, LTooltip } from 'vue2-leaflet'

import moment from 'moment'

import { stationColor, stationIcon } from 'lib/station'

export default {
  props: {
    station: {
      type: Object,
      required: true
    },

    selected: {
      type: Boolean,
      default: false
    },

    period: {
      type: Object,
      default: () => ({ count: 24, unit: 'hour' })
    },

    zoom: {
      type: Number,
      default: 3
    },

    tooltip: {
      type: Boolean,
      default: true
    },

    focus: {
      type: Boolean,
      default: true
    },

    pane: {
      type: String
    }
  },

  computed: {
    coords () {
      if (this.station.coords) {
        return [this.station.coords.lat, this.station.coords.lon]
      } else {
        console.error('Station has no lonlat', this.station)
      }
    },

    icon () {
      return L.icon({
        iconUrl: require(`images/markers/${this.iconName}.png`),
        iconRetinaUrl: require(`images/markers/${this.iconName}-2x.png`),
        iconSize: this.iconSize,
        iconAnchor: this.iconAnchor
      })
    },

    focused () {
      return false
    },

    iconName () {
      return stationIcon(this.station.type)
    },

    iconSize () {
      if (this.zoom > 11) {
        return [35, 54]
      } else if (this.zoom > 8) {
        return [23, 36]
      } else if (this.zoom > 6) {
        return [16, 24]
      } else if (this.zoom > 4) {
        return [12, 18]
      } else {
        return [8, 12]
      }
    },

    iconAnchor () {
      const [width, height] = this.iconSize
      return [width / 2, height]
    },

    tooltipOffset () {
      if (this.zoom > 10) {
        return [16, -8]
      } else {
        return [10, -5]
      }
    },

    tooltipClass () {
      if (this.station.type === 'puc') {
        return 'puc-map-window'
      }
    },

    timeSinceLastDetection () {
      const latestDetectionAt = moment.parseZone(this.station.latestDetectionAt)
      const now = moment(new Date())
      return moment.duration(now.diff(latestDetectionAt))
    },

    hoursSinceLastDetection () {
      return this.timeSinceLastDetection.asHours()
    },

    isStale () {
      return this.hoursSinceLastDetection > 24 && this.station.type != 'puc'
    },

    hasDetections () {
      return this.station.latestDetectionAt && this.periodStartDate.isBefore(this.station.latestDetectionAt)
    },

    borderWidth () {
      return (24 - Math.min(this.hoursSinceLastDetection, 9)) / 24 * (this.radius / 2)
    },

    color () {
      if (this.isStale) {
        return 'gray'
      } else {
        return stationColor(this.station.type)
      }
    },

    opacity () {
      //return this.isStale ? 0.25 : (24 - Math.min(this.hoursSinceLastDetection, 6)) / 24
      return this.focus ? 1 : 0.25
    },

    periodStartDate () {
      if (this.period.from) {
        return moment(this.period.from)
      } else {
        return moment().subtract(this.period.count, `${this.period.unit}s`)
      }
    }
  },

  methods: {
    loadInfo () {
      this.$refs.info.loadData()
    }
  },

  components: {
    LCircle,
    LMarker,
    LTooltip,
    StationInfo
  }
}
</script>
