<template>
  <MountingPortal mountTo="body" append>
    <Modal ref="modal" sizeClass="modal-lg" @show="$emit('show')" @hide="$emit('hide')">
      <template v-slot:title>
        Species Probabilities for <strong>{{ station.name }}</strong>
      </template>

      <div v-if="$apollo.queries.probabilityData.loading" class="text-center text-muted p-5">
        <font-awesome-icon icon="spinner" spin />
        <p class="mt-2 small">Loading species probabilities...</p>
      </div>

      <div v-else-if="filteredProbabilities.length == 0" class="text-center text-muted p-5">
        <p class="small">No species were found that matched your search query.</p>
      </div>

      <RecycleScroller v-else ref="scroller" class="scrollable" :items="filteredProbabilities" :item-size="itemHeight" key-field="speciesId" :style="{ height: `${containerHeight}px` }">
        <template v-slot="{ item }">
          <SpeciesProbabilityRow :species="item.species" :weeks="item.weeks" :months="item.months" />
        </template>
      </RecycleScroller>

      <template v-slot:footer>
        <div class="flex-fill d-flex justify-content-between align-items-center">
          <input type="search" class="form-control rounded-pill w-50" placeholder="Search species..." v-model="filter" :disabled="$apollo.queries.probabilityData.loading" />
          <button type="button" class="btn btn-secondary px-4" @click="hide">Close</button>
        </div>
      </template>
    </Modal>
  </MountingPortal>
</template>

<script>
import Modal from './Modal'
import SpeciesProbabilityRow from './SpeciesProbabilityRow'

import { RecycleScroller } from 'vue-virtual-scroller'

import SpeciesProbabilitiesQuery from 'queries/SpeciesProbabilities.graphql'

export default {
  props: {
    station: {
      type: Object,
      required: true
    },

    itemHeight: {
      type: Number,
      default: 48
    },

    perPage: {
      type: Number,
      default: 15
    }
  },

  data () {
    return {
      probabilityData: null,
      filter: ''
    }
  },

  apollo: {
    probabilityData: {
      query: SpeciesProbabilitiesQuery,

      variables () {
        return {
          stationId: this.station.id
        }
      }
    }
  },

  computed: {
    filteredProbabilities () {
      if (this.filter == '') {
        return this.probabilityData.probabilities
      } else {
        return this.probabilityData.probabilities.filter(p => this.matchesFilter(p.species))
      }
    },

    containerHeight () {
      return this.itemHeight * this.perPage;
    }
  },

  methods: {
    show () { this.$refs.modal.show() },
    hide () { this.$refs.modal.hide() },

    matchesFilter (species) {
      const name = [species.commonName, species.scientificName].join(' ')
      return name.toLowerCase().search(this.filter.toLowerCase()) >= 0
    }
  },

  components: {
    Modal,
    RecycleScroller,
    SpeciesProbabilityRow
  }
}
</script>
